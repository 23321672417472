<template>
    <div class="help-categories">
        <a
            :href="getRoute(topicKey)"
            class="accordion-button accordion-button-top"
            :title="t.frontend.to + ' ' + getTitle(topicKey)"
            :aria-expanded="isMain ? 'true' : 'false'"
        >
            <span>{{ getTitle(topicKey) }}</span>
        </a>
        <template v-for="(section, key) in sections" :key="key">
            <p class="areas">{{ section.name }}</p>
            <div class="accordion">
                <div v-for="(mainCategory, key) in section.mainCategories" :key="key" class="accordion-item">
                    <a
                        :href="getRoute(topicKey) + '/' + mainCategory.locale.slug"
                        class="accordion-button"
                        :title="`${t.frontend.to} ${mainCategory.locale.title}`"
                        :aria-expanded="mainCategory.id == helpMainCategoryId ? 'true' : 'false'"
                    >
                        <span class="accordion-title">{{ mainCategory.locale.title }}</span>
                    </a>
                    <div v-if="mainCategory.id == helpMainCategoryId" class="accordion-content">
                        <div class="details">
                            <details
                                v-for="helpCategory in helpCategoriesList"
                                :key="helpCategory.id"
                                :open="articleCategoryId == helpCategory.id ? true : false"
                            >
                                <summary>{{ helpCategory.locale.name }}</summary>
                                <ul>
                                    <li
                                        v-for="currentArticle in sortedArticles(helpCategory.articleList)"
                                        :key="currentArticle.id"
                                        :class="{ active: articleId == currentArticle.id }"
                                    >
                                        <a
                                            :href="
                                                getRoute(topicKey) + '/' + currentArticle.id + '-' + currentArticle.slug
                                            "
                                            >{{ currentArticle.title }}</a
                                        >
                                    </li>
                                </ul>
                            </details>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: ["articleId", "articleCategoryId", "helpMainCategoryId", "helpCategoriesList", "topicKey", "isMain"],
    data() {
        return {
            t: window.globalJs.translations,
            globalRoute: window.globalJs.route,
            sections: [],
        }
    },
    async created() {
        await this.getSections()
    },
    methods: {
        async getSections() {
            const headers = {
                "Content-Type": "application/json; charset=UTF-8",
                "X-CSRF-TOKEN": document.querySelector("meta[name=csrf-token]").content,
            }
            const response = await window.globalJs.axios.post(
                `${this.getRoute(this.topicKey)}/get-sections`,
                { topic_key: this.topicKey },
                {
                    headers: headers,
                },
            )
            if (response) {
                this.sections = response.data
            }
        },
        getRoute(topicKey) {
            if (this.globalRoute) {
                for (const [key, value] of Object.entries(this.globalRoute)) {
                    if (key == this.topicKey) {
                        return value
                    }
                }
            }
            return ""
        },
        getTitle(topicKey) {
            if (this.t && this.t.help) {
                for (const [key, value] of Object.entries(this.t.help)) {
                    if (key == this.topicKey + "_homepage") {
                        return value
                    }
                }
            }
            return ""
        },
        sortedArticles(articles) {
            return articles.slice().sort((a, b) => a.sort_index - b.sort_index)
        },
    },
}
</script>
