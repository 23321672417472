import { createApp } from "vue"
import HelpNavigation from "@/components/Frontend/HelpNavigation.vue"

const helpApp = createApp({})

const components = {
    "help-navigation": HelpNavigation,
}
Object.entries(components).forEach(([name, component]) => {
    helpApp.component(name, component)
})

helpApp.mount("#helpCatalogMobile")

export default function (activateModule) {
    // const copyLinkButtons = document.querySelectorAll(".copy-link")
    const copyLinkNotify = document.getElementById("copy-link-notify")
    const navigationCatalog = document.getElementById("navigationCatalog")
    const helpCatalogMobile = document.getElementById("helpCatalogMobile")

    if (activateModule == "navigation") {
        navigationHandler()
    } else {
        searchHandler(null)
    }

    // copyLinkButtons.forEach((linkButton) => {
    //     linkButton.addEventListener("click", () => searchHandler(linkButton), { passive: true })
    // })

    if (navigationCatalog && helpCatalogMobile) {
        navigationCatalog.addEventListener("click", navigationHandler, { passive: true })
    }

    function navigationHandler() {
        navigationCatalog.classList.toggle("_open")
        helpCatalogMobile.classList.toggle("close-catalog")
    }

    function searchHandler(linkButton) {
        const input = document.createElement("input")
        input.style.cssText = "opacity: 0; position: absolute;"
        input.value = window.location.href
        input.setAttribute("readonly", true)
        input.setAttribute("contenteditable", true)
        document.body.appendChild(input)
        input.select()
        input.setSelectionRange(0, 99999)
        document.execCommand("copy")
        document.body.removeChild(input)

        if (linkButton) {
            linkButton.title = "Der Link wurde kopiert."
            linkButton.focus()
        } else {
            document.querySelector(".help-search input").focus()
        }

        if (copyLinkNotify && linkButton) {
            copyLinkNotify.style.display = "block"
            setTimeout(() => {
                copyLinkNotify.style.display = "none"
            }, 2000)
        }
    }
}
